<div class="casino-offers">
  <p
    [innerHTML]="getOfferHelpText() | translate"
    class="choose-offer-text"
    [ngStyle]="{
      visibility:
        !isAnyOfferSelected || isSelectAllDisabled || this.isLoginFlow || this.isMobileFlow ? 'visible' : 'hidden'
    }"
  >
    >
  </p>
  <div class="slider-gradient-left"></div>

  <swiper-container
    init="false"
    #mySwiper
    [ngClass]="'swiper-slider'"
    navigation="true"
    pagination="true"
    *ngIf="hasEnhancedCasino"
  >
    <swiper-slide *ngFor="let casino of casinos | slice : 0 : 1" (click)="checkCasino(casinos[0])">
      <div class="slider-item">
        <div class="slider-item__inner-card-wrapper">
          <div [ngClass]="getCasinoStatusClass(casino)" class="slider-item__inner-card first-row">
            <img class="casino-agb__logo-img" [src]="casino.logoUrl | safeUrl" />
            <div
              *ngIf="casino.agb_status === 'checked'"
              [innerHTML]="'offer_status_' + getCasinoStatus(casino) | translate"
            ></div>
          </div>
        </div>
      </div>
    </swiper-slide>

    <swiper-slide *ngIf="screenWidth >= 1920" (click)="checkCasino(casinos[0])"> </swiper-slide>
    <swiper-slide *ngIf="screenWidth >= 1920" (click)="checkCasino(casinos[0])"> </swiper-slide>
    <swiper-slide *ngIf="screenWidth >= 1920" (click)="checkCasino(casinos[0])"> </swiper-slide>
    <swiper-slide *ngFor="let casino of casinos | slice : 1">
      <div (click)="checkCasino(casino)" class="slider-item">
        <div class="slider-item__inner-card-wrapper">
          <div [ngClass]="getCasinoStatusClass(casino)" class="slider-item__inner-card">
            <img class="casino-agb__logo-img" [src]="casino.logoUrl | safeUrl" />
            <div
              *ngIf="casino.agb_status === 'checked'"
              [innerHTML]="'offer_status_' + getCasinoStatus(casino) | translate"
            ></div>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
  <swiper-container
    init="false"
    #mySwiper
    [ngClass]="casinos.length <= 3 ? 'swiper-slider-' + casinos.length : 'swiper-slider'"
    navigation="true"
    pagination="true"
    *ngIf="!hasEnhancedCasino"
  >
    <swiper-slide *ngFor="let casino of casinos">
      <div (click)="checkCasino(casino)" class="slider-item">
        <div class="slider-item__inner-card-wrapper">
          <div [ngClass]="getCasinoStatusClass(casino)" class="slider-item__inner-card">
            <img class="casino-agb__logo-img" [src]="casino.logoUrl | safeUrl" />
            <div
              *ngIf="casino.agb_status === 'checked'"
              [innerHTML]="'offer_status_' + getCasinoStatus(casino) | translate"
            ></div>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
  <div class="slider-gradient-right"></div>
  <div class="casino-offers__buttons-wrapper">
    <button
      *ngIf="isLoginFlow && !isMobileFlow"
      class="go_back_button"
      (click)="goBackToLoggedInPage()"
      [innerHTML]="'logged_in_offers_back_button' | translate"
    ></button>
    <button
      *ngIf="isLoginFlow"
      class="select-all-casino-offers"
      (click)="selectAll()"
      [innerHTML]="'select_all_button_text' | translate"
      [disabled]="isSelectAllDisabled"
    ></button>
    <app-vs-continue-button
      (click)="confirmStepThree()"
      [isSubmitting]="isSubmitting"
      [isDisabled]="isSaveButtonDisabled || registrationForm?.invalid"
      [textButton]="isLoginFlow ? 'logged_in_offers_save_button' : 'registration_step_3_next_button'"
    ></app-vs-continue-button>
  </div>
  <div class="casino-multilog-logo">
    <img src="./assets/logo/logo-multilog.png" alt="" srcset="" />
  </div>
</div>
