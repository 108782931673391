import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { CustomerService } from '@app/modules/customer/services/customer.service';
import {
  MERKUR_BETS,
  MERKUR_SLOTS,
  OFFER_REGISTRATION_ERROR,
  OFFER_REGISTRATION_PENDING,
  OFFER_REGISTRATION_SUCCESS,
  PAYLADO_OFFER_NAME
} from '@app/modules/registration/utils/constants';
import { CasinoData } from '@app/shared/models/casino-data';
import { NotificationService } from '@app/shared/services/notification.service';
import { partner } from '@partners/partner';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';
import { DialogModalButtons } from '../dialog-popup/enums/dialog-modal.enum';
import { RegistrationService } from '@app/modules/registration/services/registration.service';
import { HelperService } from '@app/shared/services/helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslationService } from '@app/shared/services/translation.service';
import { PopupService } from '@app/shared/services/popup.service';
import { Popup } from '@app/shared/models/popup';
import { DataShareService } from '@app/shared/services/data-share.service';
import { TerminalDataService } from '@app/shared/services/terminal-data.service';
import { PrinterService } from '@app/modules/customer/services/printer.service';
import { ThemeService } from '@app/modules/layout/services/theme.service';

@Component({
  selector: 'app-casino-offers',
  templateUrl: './casino-offers.component.html',
  styleUrls: ['./casino-offers.component.scss']
})
export class CasinoOffersComponent implements OnDestroy, AfterViewInit, OnInit {
  @ViewChild('payladoCheckbox') private payladoCheckbox: MatCheckbox;
  @ViewChild('selectAllCheckbox') private selectAllCheckbox: MatCheckbox;
  @ViewChildren('defaultCheckbox') private defaultCheckbox: QueryList<MatCheckbox>;
  @ViewChild('mySwiper') mySwiperRef: ElementRef;
  @Input() registrationForm: FormGroup;
  @Input() isButtonDisabled?: boolean;
  @Input() isSubmitting?: boolean;
  @Input() isLoginFlow: boolean;
  @Output() public stepFinished: EventEmitter<void> = new EventEmitter();
  public checkedCasinos = [];
  public casinos: CasinoData[] = [];
  public casinosToPrint: CasinoData[] = [];
  public videoIdentVerified = 1;
  public turkishIsoCode = 'tr';
  public showOptIn = true;
  public selectedAll = false;
  private allSubscriptions: Subscription[] = [];

  public sliderRows: number;
  public sliderSlidesPerView: string;
  public sliderConfig;
  public payladoPreselected = false;
  public isAnyOfferPreselected = true;

  isMobileFlow = false;
  hasEnhancedCasino = false;

  screenWidth: number = 0;

  tAndCPopup: Popup = {
    data: {
      cssClass: 'popup__tac-text',
      content: '',
      withTranslation: false
    },
    height: '70%',
    width: '60%',
    panelClass: 'popup',
    autoFocus: false
  };

  constructor(
    private dialog: MatDialog,
    private customerService: CustomerService,
    private notificationService: NotificationService,
    private registrationService: RegistrationService,
    private helperService: HelperService,
    private translationService: TranslationService,
    private popupService: PopupService,
    private dataShareService: DataShareService,
    private router: Router,
    private terminalDataService: TerminalDataService,
    private printerService: PrinterService,
    private cdRef: ChangeDetectorRef,
    public activatedRoute: ActivatedRoute,
    private themeService: ThemeService
  ) {
    const dataCasinoShareSub = this.dataShareService.casinos.subscribe(
      casinos => {
        this.casinos = casinos;
        this.checkPreselectedCasino();

        if (!casinos.length) {
          this.showOptIn = false;
        }
      },
      error => {
        this.notificationService.showMessage(error.error.error, 'error', true);
      }
    );

    this.allSubscriptions.push(dataCasinoShareSub);

    this.casinos.sort((a, b) => {
      if (a.agb_status < b.agb_status) return 1;
      if (a.agb_status > b.agb_status) return -1;
      if (a.registration_status !== b.registration_status) {
        if (b.registration_status === 'rejected') return -1;
        if (a.registration_status === 'rejected') return 1;
        if (a.registration_status < b.registration_status) return -1;
        if (a.registration_status > b.registration_status) return 1;
      }

      //// if (a.id < b.id) return -1;
      //// if (a.id > b.id) return 1;

      return 0;
    });
  }
  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.hasEnhancedCasino = this.casinos.some(casino => casino.isFavorite && casino.agb_status !== 'checked');
    this.activatedRoute.queryParams.subscribe(params => {
      const token = params['token'];

      if (token) {
        this.isMobileFlow = true;
      }
    });
    this.checkedCasinos = this.casinos.filter(casino => casino.agb_status === 'checked');
    this.selectedAll =
      this.checkedCasinos.length === this.casinos.length &&
      this.checkedCasinos.every(casino => casino.agb_status === 'checked');
  }

  ngAfterViewInit(): void {
    if (!this.isAnyOfferPreselected) {
      this.registrationForm?.setErrors({ invalid: true });
    }

    const swiperParams = {
      slidesPerView: 2,
      spaceBetween: 20,

      grid: { fill: 'row', rows: this.casinos.length > 1 ? this.casinos.length / 2 : 1 },
      breakpoints: {
        992: {
          /* slidesPerView: 3,
          spaceBetween: 20, */
          grid: { fill: 'row', rows: this.casinos.length <= 3 ? 1 : 2 }
        },
        1100: {
          slidesPerView: 3,
          spaceBetween: 20,
          grid: { fill: 'column', rows: this.casinos.length <= 3 ? 1 : 2 }
        },
        1900: {
          slidesPerView: this.casinos.length <= 3 ? this.casinos.length : this.casinos.length <= 6 ? 3 : 3.5,
          spaceBetween: 20,
          grid: { fill: 'column', rows: this.casinos.length <= 3 ? 1 : 2 }
        }
      },
      on: {
        init() {}
      }
    };

    Object.assign(this.mySwiperRef.nativeElement, swiperParams);
    this.mySwiperRef.nativeElement.initialize();
    this.cdRef.detectChanges();
  }

  checkPreselectedCasino(): void {
    this.dataShareService.selectedOffer.subscribe(id => {
      if (id) {
        const casinoToSelect = this.casinos.find(casino => casino.id === id);
        this.checkCasino(casinoToSelect);
      } else {
        this.isAnyOfferPreselected = false;
      }
    });
  }

  displayFirstTranslationPart(): boolean {
    const currentLanguage = this.translationService.getCurrentLanguage();
    return currentLanguage !== this.turkishIsoCode;
  }

  checkCasino(casino: CasinoData): void {
    const linkedCasinos = [MERKUR_BETS, MERKUR_SLOTS];
    const casinoNameLower = casino.name.toLowerCase();
    const isPayladoCasino = casino.name.toLowerCase() === 'paylado';

    const removeNonPayladoSelectableCasinos = () => {
      this.checkedCasinos = this.checkedCasinos.filter(
        checkedCasino => checkedCasino.name.toLowerCase() === 'paylado' || checkedCasino.agb_status === 'checked'
      );
    };
    if (casino.agb_status === 'checked') {
      return;
    }

    if (linkedCasinos.includes(casinoNameLower)) {
      const linkedCasinoIds = linkedCasinos.map(name => {
        return this.casinos.find(c => c.name.toLowerCase() === name);
      });

      const linkedCasinoSelected = linkedCasinoIds.some(casino => this.checkedCasinos.includes(casino));

      if (linkedCasinoSelected) {
        if (this.isLoginFlow) {
          this.checkedCasinos = this.checkedCasinos.filter(id => !linkedCasinoIds.includes(id));
        } else {
          removeNonPayladoSelectableCasinos();
        }
      } else {
        if (!this.isLoginFlow) {
          removeNonPayladoSelectableCasinos();
        }
        linkedCasinoIds.forEach(linkedCasino => {
          if (linkedCasino && !this.checkedCasinos.includes(linkedCasino)) {
            linkedCasino.selectedAfter = true;
            this.checkedCasinos.push(linkedCasino);
          }
        });
      }
    } else {
      if (this.checkedCasinos.includes(casino)) {
        this.checkedCasinos = this.checkedCasinos.filter(checkedCasino => checkedCasino.id !== casino.id);
      } else {
        if (!isPayladoCasino && !this.isLoginFlow) {
          removeNonPayladoSelectableCasinos();
        }
        casino.selectedAfter = true;
        this.checkedCasinos.push(casino);
      }
    }
    if (this.checkedCasinos.length > 0) {
      this.registrationForm?.setErrors(null);
    } else {
      this.registrationForm?.setErrors({ invalid: true });
    }
  }

  confirmStepThree() {
    this.casinosToPrint = this.checkedCasinos.filter(casino => casino.selectedAfter === true);
    this.dataShareService.updateCasinosToPrint(this.casinosToPrint);

    if (!this.checkedCasinos.length) return;
    const terminalHasPaylado = this.casinos.some(casino => casino.name.toLocaleLowerCase() === PAYLADO_OFFER_NAME);
    const isPayladoChecked = this.checkedCasinos.some(casino => casino.name.toLocaleLowerCase() === PAYLADO_OFFER_NAME);

    if (!isPayladoChecked && terminalHasPaylado) {
      this.showAreYouSurePayladoDialog();
      return;
    }
    this.redirectToNextView();
  }

  showAreYouSurePayladoDialog(): void {
    const payladoDialogConfig = {
      data: {
        cssClass: 'popup__paylado',
        withTranslation: true,
        buttons: {
          actionCancel: __('without_paylado_dialog_btn'),
          actionConfirm: __('select_paylado_dialog_btn')
        },
        content: __('are_you_sure_paylado_modal_message'),
        imgSrc: './assets/images/paylado-device.png',
        closeBtn: true
      },
      width: '60%',
      height: '60%',
      panelClass: 'popup',
      autoFocus: false,
      disableClose: true
    };

    const dialogSubscribe = this.dialog.open(DialogPopupComponent, payladoDialogConfig);
    const dialogSub = dialogSubscribe.afterClosed().subscribe(response => {
      const { CONFIRM, CANCEL } = DialogModalButtons;

      if (response === CANCEL) this.redirectToNextView();

      if (response === CONFIRM) {
        const paylado = this.casinos.find(casino => casino.name.toLocaleLowerCase() === PAYLADO_OFFER_NAME);
        this.checkCasino(paylado);
        this.redirectToNextView();
      }
    });
    this.allSubscriptions.push(dialogSub);
  }

  openInfoDialog(casino: CasinoData, textType: string): void {
    const currentLanguage = this.translationService.getCurrentLanguage();
    const pathToFile =
      textType === 'agb'
        ? 'assets/documents/agbs/' + currentLanguage + '/' + casino.name + '.html'
        : 'assets/documents/privacy-agreements/' + currentLanguage + '/' + casino.name + '.html';
    const popUpServiceSub = this.popupService
      .getFileContent(pathToFile)
      .pipe(take(1))
      .subscribe(data => {
        this.tAndCPopup.data.content = data;
        this.popupService.openDialog(this.tAndCPopup);
      });

    this.allSubscriptions.push(popUpServiceSub);
  }

  redirectToNextView(): void {
    const isPayladoChecked = this.checkedCasinos.some(casino => casino.name.toLocaleLowerCase() === PAYLADO_OFFER_NAME);

    if (isPayladoChecked) {
      this.dataShareService.setPayladoIsSelected(true);
    }
    this.dataShareService.updateCasinosToSend(this.checkedCasinos);
    this.stepFinished.emit();
  }

  goBackToLoggedInPage(): void {
    this.router.navigate(['/app/customer/logged-in-page'], { queryParamsHandling: 'merge' });
  }

  get isAnyOfferSelected(): boolean {
    return this.checkedCasinos.length >= 1;
  }

  get isSelectAllDisabled(): boolean {
    return (
      this.checkedCasinos.length === this.casinos.length &&
      this.checkedCasinos.every(casino => casino.agb_status === 'checked')
    );
  }

  get isSaveButtonDisabled(): boolean {
    const isAnyOfferSelectedAfter = this.checkedCasinos.some(casino => casino.selectedAfter);
    return !isAnyOfferSelectedAfter;
  }

  getOfferHelpText(): string {
    if (this.isSelectAllDisabled || this.casinos.length === 0) {
      return 'all_casinos_selected';
    }
    if (!this.isAnyOfferSelected) {
      return 'no_casino_selected';
    }
    if (this.isLoginFlow || this.isMobileFlow) {
      return 'choose_additional_offers';
    }
    return '';
  }

  selectAll() {
    if (this.isSelectAllDisabled) {
      return;
    }
    if (!this.selectedAll) {
      this.casinos.forEach(casino => {
        if (!this.checkedCasinos.includes(casino) && casino.agb_status !== 'checked') {
          casino.selectedAfter = true;
          this.checkedCasinos.push(casino);
        }
      });
    } else {
      this.checkedCasinos = this.checkedCasinos.filter(casino => casino.agb_status === 'checked');
      this.checkedCasinos.forEach(casino => (casino.selectedAfter = false));
    }
    if (this.checkedCasinos.length > 0) {
      this.registrationForm?.setErrors(null);
    } else {
      this.registrationForm?.setErrors({ invalid: true });
    }
    this.selectedAll = !this.selectedAll;
  }

  ngOnDestroy(): void {
    for (const subscription of this.allSubscriptions) {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    }
  }

  getCasinoStatusClass(casino): { [key: string]: boolean } {
    return {
      success: casino.registration_status === OFFER_REGISTRATION_SUCCESS,
      error: casino.registration_status === OFFER_REGISTRATION_ERROR,
      pending:
        casino.registration_status !== OFFER_REGISTRATION_ERROR &&
        casino.registration_status !== OFFER_REGISTRATION_SUCCESS &&
        casino.agb_status === 'checked',
      selected: this.checkedCasinos.includes(casino),
      greyedOutBg:
        !this.isLoginFlow &&
        this.checkedCasinos.some(c => c.selectedAfter && c.name.toLowerCase() !== 'paylado') &&
        !this.checkedCasinos.includes(casino) &&
        casino.name.toLowerCase() !== 'paylado' &&
        casino.agb_status !== 'checked'
    };
  }

  getCasinoStatus(casino): string {
    if (casino.registration_status === OFFER_REGISTRATION_SUCCESS) return 'done';
    if (casino.registration_status === OFFER_REGISTRATION_ERROR) return 'rejected';
    if (casino.agb_status === 'checked') return 'pending';
  }

  checkForCasino(id: string): boolean {
    return this.checkedCasinos.includes(id);
  }
}
